import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import {Content} from './session-3'
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Content />
    <hr />
    <h1>Archive</h1>
    <ul>

    <li><Link to="/session-2/">Session 2</Link></li>
    </ul>

    <hr />
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
  </Layout>
)

export default IndexPage
